import React from 'react'
import styled from 'styled-components'

import Poster from '../assets/images/wendi-and-lucas-anniversary-illustration-xl.jpg'

const Header = props => <HeaderStyle id="header" className="alt"></HeaderStyle>

const HeaderStyle = styled.header`
  background-image: url(${Poster});
  background-color: #f7f1d1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 24px);
  min-height: 400px;
  max-height: 1000px;
`
export default Header
