import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import styled from 'styled-components'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import birdo from '../assets/images/birdo.jpg'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <>
        <Header />
        <Layout>
          <Helmet
            title="Wendi and Lucas Anniversary"
            description="You are cordially invited to join Lucas and Wendi to celebrate our
              10 years together."
          />

          <Waypoint
            onEnter={this._handleWaypointEnter}
            onLeave={this._handleWaypointLeave}
          ></Waypoint>
          <Nav sticky={this.state.stickyNav} />

          <div id="main">
            <section id="intro" className="main">
              <div className="spotlight">
                <div className="content">
                  <header className="major">
                    <h2>
                      Celebrate 10 Years of Awesome with Wendi &amp; Lucas
                    </h2>
                  </header>
                  <h2>July 6th, 2019</h2>
                  <p>
                    You are cordially invited to join us (Lucas and Wendi!) to
                    celebrate our 10 years together as two humans married to one
                    another on this planet. We’re inviting all of the people who
                    have touched our lives over the years, so of course you know
                    that we couldn’t throw this party without YOU!
                  </p>
                  <p>We really hope you can join us!</p>
                </div>
                <span className="image">
                  <img src={birdo} alt="Birdo" />
                </span>
              </div>
            </section>

            <section id="date-time" className="main special">
              <header className="major">
                <h2>Arriving at Casa de Awesome</h2>
              </header>
              <p>
                Our address is
                <br />
                <a
                  href="https://goo.gl/maps/FkvJNH5KpAEjWsCF8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  15903 SE Oatfield Road
                  <br />
                  Oak Grove, OR 97267
                </a>
              </p>
              <NarrowStyle>
                <p>
                  You can’t see the Casa from the street, and the turn into our
                  driveway is sharp and somewhat steep, so be careful and go
                  slowly once you near our house. It's immediately after the
                  Thiessen intersection.
                </p>
                <p>
                  We have lots of parking in the circular drive, but if that’s
                  full you’ll need to find street parking further down on
                  Oatfield or up on Thiessen.
                </p>
              </NarrowStyle>
            </section>

            <section id="accomodations" className="main special">
              <header className="major">
                <h2>Accomodations</h2>
                <NarrowStyle>
                  <p>
                    Please don’t drink and drive! We have lots of space at the
                    Casa, so if you’d like to stay with us, simply let us know
                    in advance and we’ll make up a room for you.
                  </p>
                </NarrowStyle>
              </header>
            </section>

            <section id="amusements" className="main special">
              <header className="major">
                <h2>Amusements</h2>
              </header>
              <p>
                It wouldn’t be one of our parties if we didn’t have a twist.
                We’ve devised a game that celebrates all of our friendships and
                the adventures we’ve had called How Do You Know Wendi and Lucas?
                This question will trigger the player to now tell the story of
                how they know us using the names, places, quotes, situations,
                etc. from actual experiences we have had with at least one of
                the people present. These prompts will be created by Wendi and
                given to each player before the event. The player can tell the
                story with whatever embellishments they like, but they must use
                the words they were given before the party. To add a little
                extra fun, we (Lucas and Wendi) will have the power to interject
                when we hear a story being told. If one of us says, “Forgive me
                but I thought...” whatever follows that phrase must be
                incorporated into the telling of the player’s story.
              </p>
              <p>
                Example: “Forgive me but I thought we were in Europe when that
                happened.”{' '}
              </p>
              <p>
                By the end of the night everyone should have told their story
                multiple times, and probably never the exact same way. At that
                point, just for kicks, we can draw names, and whoever’s name is
                drawn is the story the person who drew the name will have to
                retell, sort of like a game of Telephone. So if you like to spin
                a tall tale, this is your chance to make it memorable!
              </p>
            </section>

            <section id="appreciation" className="main">
              <NarrowStyle>
                <header className="major">
                  <h2>Appreciation</h2>
                </header>
                <p>
                  If you are inclined to gift-giving, or if you want to
                  celebrate us but can't make it to the event:{' '}
                  <ul>
                    <li>Artists/makers could honor us with a creation</li>
                    <li>
                      Gardeners could help us diversify our land with something
                      from their own
                    </li>
                    <li>
                      If you’d like to nourish our bellies,{' '}
                      <a
                        href="https://squareup.com/gift/D983D39DC6R2G/order"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ichiza Kitchen
                      </a>{' '}
                      has our heart
                    </li>
                    <li>
                      If you’d like to nourish our minds, you can contribute to
                      our annual jólabókaflóð tradition with an{' '}
                      <a
                        href="https://www.powells.com/gifts/giftcards?gclid=CjwKCAjw583nBRBwEiwA7MKvoLmq1Ygrix_MXiGelm2Q4oqBKHHsYC2CDaEMlAEbXc0ZeBNbvmCWXRoCES8QAvD_BwE"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        eGift Card
                      </a>{' '}
                      to Powell’s Books
                    </li>
                  </ul>
                </p>
              </NarrowStyle>
            </section>

            <section id="adornment" className="main">
              <NarrowStyle>
                <header className="major">
                  <h2>Adornment</h2>
                </header>
                <p>
                  Dress however you like! Costumes are great, but so is being
                  comfortable and ready for backyard hangouts. We’ll have a fire
                  going in the fire pit once the sun starts going down, but hey,
                  it’s the PNW. Layers never hurt.
                </p>
                <footer className="major">
                  <ul className="actions">
                    <li>
                      <a
                        href="mailto:wendi.y.anderson@gmail.com"
                        className="button"
                      >
                        Contact Wendi
                      </a>
                    </li>
                    <li>
                      <a href="mailto:lucas@lucastswick.com" className="button">
                        Contact Lucas
                      </a>
                    </li>
                  </ul>
                </footer>
              </NarrowStyle>
            </section>
          </div>
        </Layout>
      </>
    )
  }
}

const NarrowStyle = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export default Index
