import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = props => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['intro', 'date-time', 'accomodations', 'amusements']}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="intro">
          <a href="#intro">Introduction</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="date-time">
          <a href="#date-time">When &amp; Where</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="accomodations">
          <a href="#accomodations">Accomodations</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="amusements">
          <a href="#amusements">Amusements</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
